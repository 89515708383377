<template lang="pug">
  .w-info.widget
    .w-info__content
      img.w-info__decor(src="@/assets/illustrations/flower-branch.svg")
      .small-title Dear
      .w-info__subtitle {{ guests }}
      p.w-info__subsubtitle We invite you to share in our joy#[br] and request your presence at our#[br] wedding. Our wedding will be on:
      img.w-info__calendar(src="@/assets/svg/calendar.svg")
      p.mb-3 We cannot imagine this joyful#[br] day without you!
</template>

<script>
export default {
  name: 'InfoWidget',

  computed: {
    invitation () {
      return this.$store.getters['invitation/invitation']
    },

    guests () {
      if (this.invitation) {
        return this.invitation.guests
          .map(item => {
            const index = item.name.indexOf('(')
            if (index !== -1) {
              return item.name.substring(0, index - 1)
            }
            return item.name
          })
          .join(', ')
          .replace(/,(?=[^,]*$)/, ' and')
      }
      return '-'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-info {
    padding-top: 8rem;
    padding-bottom: 10rem;

    &__decor {
      width: 3rem;
      display: inline-block;
      margin-bottom: 1.5rem;
    }

    &__content {
      position: relative;
      padding: 0 2rem;
      text-align: center;
      z-index: 1;
    }

    &__subtitle {
      font-size: 2.5rem;
      display: inline-block;
      text-align: center;
      min-width: 27rem;
      margin-top: 3rem;
      line-height: 1.4;
      padding-bottom: 2.5rem;
      margin-bottom: 3.2rem;
      border-bottom: 1px solid rgba($color-primary, .6);
    }

    &__subsubtitle {
      margin-bottom: 5rem;
    }

    &__calendar {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 4.5rem;
    }
  }
</style>

<template lang="pug">
  .page(v-if="hasInvitation")
    intro-widget
    info-widget
    .widget(
      key="photo"
      style="z-index: 13")
      v-animate
        img(src="@/assets/img/second.jpg")
    address-widget
    RSVP-widget
    dietary-requirements
    slider-widget
    timing-widget
    present-widget
    countdown-widget
    telegram-widget
  no-invitation-id(v-else)
</template>

<script>
import IntroWidget from '@/components/widgets/IntroWidget'
import InfoWidget from '@/components/widgets/InfoWidget'
import AddressWidget from '@/components/widgets/AddressWidget'
import DietaryRequirements from '@/components/widgets/dietary-requirements/DietaryRequirements'
import TimingWidget from '@/components/widgets/TimingWidget'
import PresentWidget from '@/components/widgets/PresentWidget'
import OurStoryWidget from '@/components/widgets/OurStoryWidget'
import RSVPWidget from '@/components/widgets/RSVPWidget'
import SliderWidget from '@/components/widgets/SliderWidget'
import CountdownWidget from '@/components/widgets/CountdownWidget'
import TelegramWidget from '@/components/widgets/TelegramWidget'
import NoInvitationId from '@/components/widgets/NoInvitationId'

export default {
  name: 'Index',

  components: {
    IntroWidget,
    InfoWidget,
    TimingWidget,
    PresentWidget,
    AddressWidget,
    DietaryRequirements,
    OurStoryWidget,
    RSVPWidget,
    SliderWidget,
    CountdownWidget,
    TelegramWidget,
    NoInvitationId
  },

  computed: {
    hasInvitation () {
      return this.$store.getters['invitation/invitation']
    }
  }
}
</script>

<style lang="scss">
</style>

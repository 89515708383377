<template lang="pug">
  .w-telegram.widget.widget--white(id="telegram")
    .w-telegram__content
      img.w-telegram__profile(src="@/assets/img/profile-03.png")
      p.w-telegram__text For a quick exchange#[br] of information, photos and videos#[br] we created a group#[br] in Telegram.
      a.btn(
        target="_blank"
        :href="chatLink")
        img.icon(src="@/assets/svg/telegram.svg")
        span Group chat
    made-by-invito
</template>

<script>
import MadeByInvito from '@/components/common/MadeByInvito'

export default {
  name: 'TelegramWidget',

  components: {
    MadeByInvito
  },

  computed: {
    chatLink () {
      return !this.$store.getters.isDemoMode
        ? 'https://t.me/+MVi6N93ZKHAwOTcy'
        : null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-telegram {
    overflow: hidden;

    .icon {
      width: 2rem;
    }

    &__content {
      position: relative;
      padding: 15rem 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    &__profile {
      width: 15rem;
      margin-bottom: 5rem;
    }

    &__text {
      margin-bottom: 5rem;
    }
  }
</style>

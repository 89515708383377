<template lang="pug">
  .widget.timing-widget
    img.timing-widget__decor(src="@/assets/illustrations/flower-branch.svg")
    .title Timing of the day
    .timing-widget__item(
      v-for="(item, index) in items"
      :key="index")
      .timing-widget__item-time {{ item.time }}
      .timing-widget__item-label {{ item.label }}
</template>

<script>
export default {
  name: 'TimingWidget',

  data: () => ({
    items: [
      { time: '1:30', label: 'Arrival time' },
      { time: '2:00', label: 'Wedding Ceremony' },
      { time: '3:00', label: 'Banquet' },
      { time: '5:30', label: 'Photo Session with guests' },
      { time: '6:00', label: 'Second part' },
      { time: '9:00', label: 'Happy End' }
    ]
  })
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .timing-widget {
    padding: 12rem 1.4rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      text-align: center;
      margin-bottom: 5rem;
    }

    &__decor {
      width: 3rem;
      margin: 0 auto;
      margin-bottom: 4rem;
    }

    &__content {
      padding: 4rem 0;
    }

    &__item {
      width: 34rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-left: 2rem;
      margin-right: 2rem;

      &:not(:last-child) {
        margin-bottom: 2.8rem;
      }

      &-time {
        width: 7rem;
        text-align: center;
        padding: 1rem 1.2rem;
        font-size: 1.4rem;
        margin-right: 2rem;
        border: .1rem solid $color-primary;
      }

      &-label {
        font-size: 1.4rem;
        line-height: 2rem;
        display: inline-flex;
      }
    }
  }
</style>

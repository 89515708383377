<template lang="pug">
  .w-address.widget
    .w-address__content
      img.w-address__decor(src="@/assets/illustrations/flower-branch.svg")
      .title WEDDING CEREMONY
      p.w-address__text Dalton House Hyde Park#[br] 181 Elizabeth Street, SYDNEY NSW 2000#[br] at 1:30 pm
      a.w-address__link(
        target="_blank"
        href="https://goo.gl/maps/QL3uQuRB73sbR6i57") see on the map
      img.w-address__decor(src="@/assets/illustrations/flower-branch.svg")
      .title BANQUET
      p.w-address__text Dalton House Hyde Park#[br] 181 Elizabeth Street, SYDNEY NSW 2000#[br] at 3 pm
      a.w-address__link(
        target="_blank"
        href="https://goo.gl/maps/QL3uQuRB73sbR6i57") see on the map
      p.w-address__text.w-address__text--small * We reccomend parking Rd Piccadilly carpark and your parking ticket will be validated for $18.
      p.w-address__text.w-address__text--small ** 3 Apr 2022 - Daylight Saving Time Ends.#[br] When local daylight time is 3:00 am on Sunday 3 April 2022, clocks are turned backward 1 hour to 2:00 am local standard time instead.
</template>

<script>
export default {
  name: 'AddressWidget'
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .w-address {
    position: relative;

    &__decor {
      width: 3rem;
      display: inline-block;
      margin-bottom: 1.5rem;
    }

    &__text {
      margin-top: 1.6rem;
      margin-bottom: 2rem;
      line-height: 2.6rem;
      max-width: 85%;

      &--small {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }

    &__link {
      position: relative;
      display: inline-block;
      color: $color-text !important;
      font-size: 1.2rem;
      letter-spacing: 0.2em;
      padding: 1.2rem 2.4rem;
      border: .1rem solid rgba($color-text, .4);
      background-color: $color-secondary;
      text-decoration: none;
      text-transform: none;
      transition: .1s;

      &::before {
        content: "";
        position: absolute;
        top: .6rem;
        left: .6rem;
        width: 100%;
        height: 100%;
        border: .1rem solid rgba($color-primary, .3);
      }

      &:active {
        transform: scale(.97);
        background-color: rgba($color-secondary, 1);
      }

      &:visited {
        color: $color-primary;
      }

      &:not(:last-child) {
        margin-bottom: 6.4rem;
      }
    }

    &__content {
      padding: 12rem 0rem;
      padding-bottom: 6rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
</style>

<template lang="pug">
  .w-dietary__form(v-if="item")
    .w-dietary__form-title {{ item.name | name }}
    .w-dietary__form-list
      .w-dietary__form-item(
        v-for="(item, i) in items"
        :key="i"
        :class="{ 'w-dietary__form-item--active': form.type === item }"
        @click="onSelect(item)")
        | {{ item }}
    input.w-dietary__form-input(
      v-if="form.type === 'Other'"
      type="text"
      placeholder="Description"
      v-model="form.description"
      @blur="update")
</template>

<script>
export default {
  name: 'DietaryRequirementsItem',

  props: {
    item: Object
  },

  mounted () {
    if (this.item.dietary) {
      this.form.type = this.item.dietary.type
      this.form.description = this.item.dietary.description
    }
  },

  filters: {
    name (value) {
      if (value) {
        const index = value.indexOf('(')
        if (index !== -1) {
          return value.substring(0, index - 1)
        }
      }
      return value
    }
  },

  data: () => ({
    items: [
      'Vegetarian',
      'Gluten free',
      'Vegan',
      'Dairy free',
      'Other'
    ],
    form: {
      type: null,
      description: null
    }
  }),

  methods: {
    onSelect (item) {
      this.form.type = this.form.type !== item
        ? item
        : null
      this.update()
    },

    update () {
      this.$emit('update', {
        id: this.item.id,
        data: this.form
      })
    }
  }
}
</script>

<style>
</style>

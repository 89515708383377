<template lang="pug">
  .w-dietary.widget
    .w-dietary__question(v-if="!(showForm || isFormToggled)")
      p.mr-4 Do you have any dietary requirements?
      button.btn(@click="isFormToggled = true") Yes
    template(v-else)
      dietary-requirements-item(
        v-for="(item, i) in guests"
        :key="i"
        :item="item"
        @update="onUpdate(i, $event)")
</template>

<script>
import DietaryRequirementsItem from './DietaryRequirementsItem'

export default {
  name: 'DietaryRequirements',

  components: {
    DietaryRequirementsItem
  },

  data: () => ({
    isFormToggled: false
  }),

  computed: {
    invitation () {
      return this.$store.getters['invitation/invitation']
    },

    guests () {
      return this.invitation
        ? this.invitation.guests
        : []
    },

    showForm () {
      return this.guests.some(item => !!item.dietary)
    }
  },

  methods: {
    onUpdate (index, { data }) {
      const guests = [...this.guests]
      guests[index].dietary = {
        ...data
      }
      this.$store.dispatch('invitation/update', {
        guests
      })
    }
  }
}
</script>

<style lang="scss">
  .w-dietary {
    padding: 7rem 2rem;
    background-color: #DCAFAC;

    &__question {
      padding: 0 2rem;
      display: flex;
      align-items: center;

      p {
        text-align: left;
      }

      .btn {
        width: 11rem;
      }
    }

    &__form {
      color: #F7EFEB;
      padding: 0 2rem;

      &:not(:last-child) {
        margin-bottom: 4rem;
      }

      &-title {
        font-size: 2rem;
        margin-bottom: 2.8rem;
      }

      &-list {
        display: flex;
        flex-wrap: wrap;
      }

      &-input {
        margin-left: 1rem;
        width: 100%;
        color: #F7EFEB;
        border-bottom: 1px solid #F7EFEB;
        background-color: transparent;
        padding: 0.6rem 0;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #F7EFEB;
          opacity: .7;
        }
      }

      &-item {
        position: relative;
        font-size: 1.4rem;
        line-height: 2.4rem;
        padding-left: 30px;
        margin-bottom: 1.8rem;
        width: 50%;

        &:after {
          content: "";
          position: absolute;
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
          left: 0;
          border: 1px solid #F7EFEB;
        }

        &--active:before {
          content: "";
          position: absolute;
          height: 1.4rem;
          width: 1.4rem;
          border-radius: 50%;
          top: 0.4rem;
          left: 0.4rem;
          background-color: #F7EFEB;
        }
      }
    }
  }
</style>

<template lang="pug">
  .w-our-story.widget
    img.w-our-story__decor(src="@/assets/illustrations/flower-branch.svg")
    .title Наша история
    .w-our-story__list
      .w-our-story__item
        v-animate
          .w-our-story__item-content
            img.w-our-story__icon(src="@/assets/svg/story-heart.svg")
            span 20.02.1996#[br] Родился#[br] Михаил
      .w-our-story__item
        v-animate
          .w-our-story__item-content
            img.w-our-story__icon(src="@/assets/svg/story-heart.svg")
            span 17.05.1998#[br] Родилась#[br] Кристина
      .w-our-story__item
        v-animate
          .w-our-story__item-content
            img.w-our-story__icon(src="@/assets/svg/story-heart.svg")
            span 30.10.2018#[br] Знакомство
      .w-our-story__item
        v-animate
          .w-our-story__item-content
            img.w-our-story__icon(src="@/assets/svg/story-heart.svg")
            span 14.04.2019#[br] Стали#[br] общаться#[br] в одной#[br] компании
      .w-our-story__item
        v-animate
          .w-our-story__item-content
            img.w-our-story__icon(src="@/assets/svg/story-heart.svg")
            span 14.02.2021#[br] Начали#[br] встречаться
      .w-our-story__item
        v-animate
          .w-our-story__item-content
            img.w-our-story__icon(src="@/assets/svg/story-heart.svg")
            span 17.05.2021#[br] Первое#[br] «люблю!»
      .w-our-story__item
        v-animate
          .w-our-story__item-content
            img.w-our-story__icon(src="@/assets/svg/story-heart.svg")
            span 12.09.2021#[br] Она сказала#[br] «да!»
      .w-our-story__item
        v-animate
          .w-our-story__item-content
            img.w-our-story__icon(src="@/assets/svg/story-heart.svg")
            span 11.12.2021#[br] Роспись#[br] в загсе
      .w-our-story__item
        v-animate
          .w-our-story__item-content
            img.w-our-story__icon(src="@/assets/svg/story-heart.svg")
            span 18.12.2021#[br] Свадьба
</template>

<script>
export default {
  name: 'OurStoryWidget'
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-our-story {
    padding: 10rem 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      margin-bottom: 6.4em;
    }

    &__decor {
      width: 3rem;
      margin-bottom: 1.5rem;
    }

    &__list {
      position: relative;
      width: 100%;

      &::before {
        content: "";
        height: 100%;
        width: .08rem;
        position: absolute;
        top: 0;
        left: 50%;
        background-color: $color-primary;
      }
    }

    &__item {
      position: relative;
      width: 50%;
      margin-bottom: 7rem;
      transform: translateY(-50%);

      &::before {
        content: "";
        position: absolute;
        height: .08rem;
        width: 4rem;
        top: 50%;
        left: 0;
        background-color: $color-primary;
      }

      &:nth-child(2n) {
        padding-right: 3.7rem;
      }

      &:nth-child(2n + 1) {
        margin-left: 50%;
        padding-left: 3.7rem;
      }

      &:nth-child(2n)::before {
        right: 0;
        left: auto;
      }

      &-content {
        display: flex;
        align-items: center;
      }

      &:nth-child(2n) &-content {
        text-align: right;
        flex-direction: row-reverse;
      }

      span {
        font-size: 1.4rem;
        letter-spacing: 0.05em;
        line-height: 2rem;
      }
    }

    &__icon {
      width: 2rem;
    }

    &__item:nth-child(2n + 1) &__icon {
      margin-right: 1.6rem;
    }

    &__item:nth-child(2n) &__icon {
      margin-left: 1.6rem;
    }
  }
</style>
